import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const ShukkaTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "受注番号", value: "acceptNo" },
  { text: "作業者", value: "worker" },
  //{ text: "前工程作業番号", value: "preProcessNo" },
  { text: "品名", value: "itemName" },
  { text: "サイズ", value: "size" },
  { text: "数量(m)", value: "suryo" },
  { text: "本数", value: "honnsu" },
  { text: "枝番：製品梱包", value: "productPack" },
  { text: "枝番：修理", value: "repairing" },
  { text: "枝番：解体", value: "disassembly" },
  { text: "枝番：乱尺", value: "confuseRule" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考1", value: "biko1" },
  { text: "備考2", value: "biko2" },
  { text: "備考3", value: "biko3" },
  { text: "備考4", value: "biko4" },
  { text: "備考5", value: "biko5" }
] as const;

export class ShukkaTable {
  workDate: string;
  updateTs: string | null;
  acceptNo: string | null;
  worker: string | null;
  preProcessNo: string | null;
  itemName: string | null;
  size: string | null;
  suryo: number | null;
  honnsu: number | null;
  productPack: string | null;
  repairing: string | null;
  disassembly: string | null;
  confuseRule: string | null;
  abnormality: string | null;
  biko1: string | null;
  biko2: string | null;
  biko3: string | null;
  biko4: string | null;
  biko5: string | null;
  denno: number | null;
  gyono: number | null;

  constructor(props: {
    workDate: string;
    acceptNo: string | null;
    worker: string | null;
    preProcessNo: string | null;
    itemName: string | null;
    size: string | null;
    suryo: number | null;
    honnsu: number | null;
    productPack: string | null;
    repairing: string | null;
    disassembly: string | null;
    confuseRule: string | null;
    abnormality: string | null;
    biko1: string | null;
    biko2: string | null;
    biko3: string | null;
    biko4: string | null;
    biko5: string | null;
    denno: number | null;
    gyono: number | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.acceptNo = props.acceptNo;
    this.worker = props.worker;
    this.preProcessNo = props.preProcessNo;
    this.itemName = props.itemName;
    this.size = props.size;
    this.suryo = props.suryo;
    this.honnsu = props.honnsu;
    this.productPack = props.productPack;
    this.repairing = props.repairing;
    this.disassembly = props.disassembly;
    this.confuseRule = props.confuseRule;
    this.abnormality = props.abnormality;
    this.biko1 = props.biko1;
    this.biko2 = props.biko2;
    this.biko3 = props.biko3;
    this.biko4 = props.biko4;
    this.biko5 = props.biko5;
    this.denno = props.denno;
    this.gyono = props.gyono;
  }
}

export const ShukkaText = {
  workDate: { text: "作業年月日", value: "workDate" },
  serialNo: { text: "シリアル番号", value: "serialNo" },
  postCode: { text: "部署コード", value: "postCode" },
  machineCode: { text: "機械名", value: "machineCode" },
  worker: { text: "作業者", value: "worker" },
  inspectionFlag: { text: "機械点検記録", value: "inspectionFlag" },
  acceptNo: { text: "受注番号", value: "acceptNo" },
  acceptNoPrefix: { text: "受注番号(上2桁)", value: "acceptNoPrefix" },
  acceptNoSuffix: { text: "受注番号(下5桁)", value: "acceptNoSuffix" },
  successFlag: { text: "受注番号完了", value: "successFlag" },
  preProcessNo: { text: "前工程作業番号", value: "preProcessNo" },
  itemName: { text: "品名", value: "itemName" },
  size: { text: "サイズ", value: "size" },
  suryo: { text: "数量(m)", value: "suryo" },
  honnsu: { text: "本数", value: "honnsu" },
  productPack: { text: "枝番：製品梱包", value: "productPack" },
  repairing: { text: "枝番：修理", value: "repairing" },
  disassembly: { text: "枝番：解体", value: "disassembly" },
  confuseRule: { text: "枝番：乱尺", value: "confuseRule" },
  repairFinish: { text: "修理品完成品", value: "repairFinish" },
  disassemblyFinish: { text: "解体線完成品", value: "disassemblyFinish" },
  drum: { text: "荷姿：ドラム", value: "drum" },
  bundle: { text: "荷姿：束", value: "bundle" },
  cases: { text: "荷姿：ケース", value: "cases" },
  palet: { text: "荷姿：パレット", value: "palet" },
  appearanceDefect: { text: "外観不良", value: "appearanceDefect" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko1: { text: "備考1", value: "biko1" },
  biko2: { text: "備考2", value: "biko2" },
  biko3: { text: "備考3", value: "biko3" },
  biko4: { text: "備考4", value: "biko4" },
  biko5: { text: "備考5", value: "biko5" }
} as const;

export class Shukka extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  machineCode: string | null;
  worker: string | null;
  inspectionFlag: string | null;
  acceptNo: string | null;
  acceptNoPrefix: string | null;
  acceptNoSuffix: string | null;
  successFlag: string | null;
  preProcessNo: string | null;
  itemName: string | null;
  size: string | null;
  suryo: number | null;
  honnsu: number | null;
  productPack: string | null;
  repairing: string | null;
  disassembly: string | null;
  confuseRule: string | null;
  repairFinish: string | null;
  disassemblyFinish: string | null;
  drum: string | null;
  bundle: string | null;
  cases: string | null;
  palet: string | null;
  appearanceDefect: string | null;
  abnormality: string | null;
  biko1: string | null;
  biko2: string | null;
  biko3: string | null;
  biko4: string | null;
  biko5: string | null;
  denno: number | null;
  gyono: number | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: Shukka);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: Shukka | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof Shukka) {
      this.postCode = _props.postCode;
      this.machineCode = _props.machineCode;
      this.worker = _props.worker;
      this.inspectionFlag = _props.inspectionFlag;
      this.acceptNo = _props.acceptNo;
      this.acceptNoPrefix = _props.acceptNoPrefix;
      this.acceptNoSuffix = _props.acceptNoSuffix;
      this.successFlag = _props.successFlag;
      this.preProcessNo = _props.preProcessNo;
      this.itemName = _props.itemName;
      this.size = _props.size;
      this.suryo = _props.suryo;
      this.honnsu = _props.honnsu;
      this.productPack = _props.productPack;
      this.repairing = _props.repairing;
      this.disassembly = _props.disassembly;
      this.confuseRule = _props.confuseRule;
      this.repairFinish = _props.repairFinish;
      this.disassemblyFinish = _props.disassemblyFinish;
      this.drum = _props.drum;
      this.bundle = _props.bundle;
      this.cases = _props.cases;
      this.palet = _props.palet;
      this.appearanceDefect = _props.appearanceDefect;
      this.abnormality = _props.abnormality;
      this.biko1 = _props.biko1;
      this.biko2 = _props.biko2;
      this.biko3 = _props.biko3;
      this.biko4 = _props.biko4;
      this.biko5 = _props.biko5;
      this.denno = _props.denno;
      this.gyono = _props.gyono;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "SHUKKA";
    this.machineCode = null;
    this.worker = null;
    this.inspectionFlag = "0";
    this.acceptNo = null;
    this.acceptNoPrefix = null;
    this.acceptNoSuffix = null;
    this.successFlag = "0";
    this.preProcessNo = null;
    this.itemName = null;
    this.size = null;
    this.suryo = null;
    this.honnsu = null;
    this.productPack = null;
    this.repairing = null;
    this.disassembly = null;
    this.confuseRule = null;
    this.repairFinish = "0";
    this.disassemblyFinish = "0";
    this.drum = "0";
    this.bundle = "0";
    this.cases = "0";
    this.palet = "0";
    this.appearanceDefect = "0";
    this.abnormality = null;
    this.biko1 = null;
    this.biko2 = null;
    this.biko3 = null;
    this.biko4 = null;
    this.biko5 = null;
    this.denno = null;
    this.gyono = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
